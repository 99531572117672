.secondary-title.speakers__secondary-title {
  margin-right: 80px;

  @media (max-width: 1320px) {
    margin-right: 0;
  }
}

.speaker-slide {
  height: 620px;
  border-radius: 40px;
  overflow: hidden;
  /* background: linear-gradient(to left, #711a81, #472081); */
  display: flex;
  flex-direction: column;

  @media (max-width: 1320px) {
    border-radius: 60px;
    height: auto;
  }
}

.speaker-slide__content {
  background: linear-gradient(to left, #711a81, #472081);
  padding: 10px;
  padding-bottom: 50px;
  padding-top: 27px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.speaker-slide__img {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  height: 406px;
  object-fit: cover;

  @media (max-width: 1320px) {
    height: 523px;
  }

}

.speaker-slide__title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.speaker-slide__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.speaker-slide__text {
  font-size: 14px;
  margin: 0;
}

.speaker-slide__links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.speaker__content {
  position: relative;
  margin-bottom: 50px;
}

.swiper-button-next.swiper-button-next,
.swiper-button-prev.swiper-button-prev {
  color: var(--white);
  opacity: 0.7;
  transition: opacity 0.3s;

  @media (max-width: 1320px) {
    height: 100%;
    top: 0;
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.swiper-button-next.swiper-button-next:hover,
.swiper-button-prev.swiper-button-prev:hover {
  opacity: 1;
}

.swiper-button-next.swiper-button-next {
  right: -40px;
}

.swiper-button-prev.swiper-button-prev {
  left: -40px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-weight: bold;
  font-size: 36px !important;
}

@media (max-width: 1320px) {
  .swiper-button-next.swiper-button-next {
    right: -20px;
  }

  .swiper-button-prev.swiper-button-prev {
    left: -20px;
  }

  .swiper-button-next.swiper-button-next::after,
  .swiper-button-prev.swiper-button-prev::after {
    display: block;
    padding: 20px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 54px;
    box-sizing: border-box;
    text-align: center;

  }
}