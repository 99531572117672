.program {}

.program-items {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;

  @media (max-width: 1320px) {
    gap: 16px;
  }
}

.program-item {
  border: 1px solid var(--yellow);
  padding: 35px;
  border-radius: 40px;
  opacity: 1 !important;

  @media (max-width: 1320px) {
    padding: 16px;
    border-radius: 30px;
  }
}

.program-item__header {
  font-size: 35px;
  color: var(--yellow);

  @media (max-width: 1320px) {
    font-size: 24px;
  }
}

.program-item__text {
  font-size: 26px;

  @media (max-width: 1320px) {
    font-size: 18px;
  }
}

.program__action {
  display: flex;
  align-items: center;
  justify-content: center;
}

.program-hidden-block {
  display: none;

}

.program-hidden-block_visible__program-items {
  margin-bottom: 0;
}

.program-hidden-block_visible {
  display: block;
}