.describe {
  background-size: cover;
}

.describe__secondary-title {
  text-align: center;

  @media (max-width: 1320px) {
    /* display: none; */
  }
}

.describe-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-bottom: 50px;

  @media (max-width: 1320px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.describe-grid-cell {}

.describe-grid-cell__header {
  font-weight: bold;
  font-size: 33px;
  margin: 0;
  margin-bottom: 28px;
  color: var(--yellow);

  @media (max-width: 1320px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.describe-grid-cell__text {
  font-size: 18px;
  margin: 0;

  @media (max-width: 1320px) {
    font-size: 16px;
  }
}

.describe-promo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;

  @media (max-width: 1320px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.describe-promo__describe-grid-cell {
  @media (max-width: 1320px) {
    width: 100%;
  }
}

.main-button.describe__main-button {
  font-size: 18px;
  padding: 22px 15px;

  @media (max-width: 1320px) {
    margin-top: 20px;
  }
}