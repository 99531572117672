.leadforms-modal--disabled {
    visibility: hidden;
    opacity: 0;
}

.leadforms-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    z-index: 1000;
}

.leadforms-form__backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
}

.leadforms-form__close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    cursor: pointer;

    border: none;
    background: transparent;
    color: white;
}

.grid-col-2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
}

.mt-30 {
    margin: 30px 0 0 0;
}

.leadforms-row-submit {
    margin-top: 15px;
    text-align: center;
}

.leadforms-form {
    margin: 30px auto 15px;
    border: 1px solid #e7ecf1;
    padding: 60px 60px 30px;
    position: relative;
    display: block;
    box-sizing: border-box;
    max-width: 700px !important;
    background: linear-gradient(to left, #c10b84, #2f2482);
    color: white;
    border: 0;
    z-index: 1000;
}

@media screen and (max-width: 768px) {
    .leadforms-form {
        padding: 60px 20px 30px;
    }
}

.flow-img {
    width: 100%;
    margin: 0 0 0 -62px;
    z-index: 4;
    position: absolute;
    top: -48px;
    text-align: center;
}

.leadforms-form input.leadforms-input {
    height: 38px;
}

.leadforms-form .leadforms-form-title {
    color: inherit;
    padding-bottom: 22px;
}

.list-options {
    margin-top: 15px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.list-options.list-options--big {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.leadforms-input__chekbox {
    display: flex;
}

.leadforms-checkbox__label {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 5px;
}

.leadforms-checkbox__label:hover {
    transition: .3s ease;
    color: #3761E9;
}

.form__label:after {
    content: '*';
    color: #f44336;
    margin-left: 4px;
}

.form-input__label {
    color: white !important;
}

.leadforms-notification {
    color: white !important;

}