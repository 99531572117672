.how {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

}

.how__secondary-title.how__secondary-title {
  text-align: left;
  font-size: 60px;
  text-transform: uppercase;
  @media (max-width: 1320px) {
    font-size: 30px;
  }
}
.how-grid {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 24px;
  margin-bottom: 128px;
}

.how-grid-cell {
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  width: 45%;
  @media (max-width: 1320px) {
    width: 100%;
  }
}

.how-grid-cell__title {
  font-size: 33px;
  color: var(--yellow);
  margin: 0;
  position: relative;
  @media (max-width: 1320px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.how-grid-cell__title::before {
  content: "";
  width: 27px;
  height: 33px;
  background: center no-repeat url("../img/yellow-zip.png");
  background-size: contain;
  position: absolute;
  left: -35px;
  top: calc(50% - 16px);
}

.how-grid-cell__text {
  margin: 0;
  font-size: 18px;
}

.how__secondary-title_enter {
  margin-bottom: 50px;
}

.how-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 30px;
}

.how-list__item {
  display: flex;
  align-items: center;
  gap: 28px;
  font-size: 33px;
  color: var(--yellow);
  @media (max-width: 1320px) {
    font-size: 24px;
  }
}

.main-button.how__main-button {
  margin-bottom: 46px;
  @media (max-width: 1320px) {
    font-size: 16px;
  }
}

.how__describe {
  font-size: 24px;
  color: var(--yellow);
  margin: 0;
}
