@import "npm:normalize.css/normalize.css";
@import "styles/fonts.css";
@import "styles/hero.css";
@import "styles/about.css";
@import "styles/describe.css";
@import "styles/program.css";
@import "styles/speakers.css";
@import "styles/experts.css";
@import "styles/how.css";
@import "styles/footer.css";
@import "styles/form.css";
@import 'styles/public-content.css';

:root {
  --xs: 0;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1320px;

  --azure: #00e1ff;
  --blue: #302482;
  --yellow: #f7ec40;
  --dark-yellow: #aca32c;
  --white: #fff;
  --dark-white: #aaa;
}

body {
  background: linear-gradient(to left, #c10b84, #2f2482);
  color: var(--white) !important;
  font-family: "TTNorms", sans-serif;
  overflow-x: hidden;
  font-weight: 500;
  font-size: 18px;
}


.container {
  margin: 0 auto;
  padding: 0 20px;
  width: var(--xxl);
  max-width: 100%;
  box-sizing: border-box;
}


.secondary-title {
  margin: 0;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 60px;
  color: var(--yellow);
  text-transform: uppercase;

  @media (max-width: 1320px) {
    font-size: 30px;
    margin-bottom: 25px;
  }
}

.secondary-text {
  font-weight: 500;
  font-size: 24px;
  margin: 0;

  @media (max-width: 1320px) {
    font-size: 18px;
  }
}

.secondary-text_yellow {
  color: var(--yellow);
}

.main-button {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 40px;
  background-color: var(--yellow);
  padding: 19px 25px;
  color: var(--blue);
  text-decoration: none;
  transition: background-color 0.3s;
  border: 0;
}

.main-button:hover {
  cursor: pointer;
  background-color: var(--dark-yellow);
}

section {
  margin-bottom: 50px;
  padding: 50px 0;

  @media (max-width: 1320px) {
    margin-bottom: 25px;
    padding: 25px 0;
  }
}

/*  hero */