.about.about {
  margin-bottom: 0;
  @media (max-width: 1320px) {
    margin-bottom: 25px;
  }
}

.about-header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: 1320px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}

.secondary-title.about__secondary-title {
  flex-shrink: 0;
  margin: 0;
  margin-right: 30px;
  text-transform: none;
  font-size: 90px;
  font-weight: 500;
  @media (max-width: 1320px) {
    font-size: 50px;
    margin-bottom: 20px;
  }
}

.about-header__secondary-text {
  margin: 0;
}

.about-content {
  padding: 30px 0 0;
  position: relative;
}

.about__list {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 438px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  @media (max-width: 1320px) {
    gap: 20px;
    width: 100%;
  }
}

.about__list li {
  padding-left: 86px;
  position: relative;
  @media (max-width: 1320px) {
    padding-left: 36px;
  }
}

.about__list li::before {
  content: "";
  width: 61px;
  height: 76px;
  background: center no-repeat url("../img/white-zip.png");
  background-size: contain;
  position: absolute;
  left: 0;
  top: calc(50% - 38px);
  @media (max-width: 1320px) {
    width: 24px;
    height: 30px;
    top: calc(50% - 15px);
  }
}

.about-content__image {
  z-index: -1;
  position: absolute;
  top: -25px;
  right: 0;
  height: calc(100% + 75px);
  @media (max-width: 1320px) {
    display: none;
  }
}
