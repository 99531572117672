@font-face {
  font-family: "TTNorms";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/TTNorms-Regular.woff2") format("woff2"),
    url("../fonts/TTNorms-Regular.woff") format("woff");
}

@font-face {
  font-family: "TTNorms";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/TTNorms-Medium.woff2") format("woff2"),
    url("../fonts/TTNorms-Medium.woff") format("woff");
}

@font-face {
  font-family: "TTNorms";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/TTNorms-Bold.woff2") format("woff2"),
    url("../fonts/TTNorms-Bold.woff") format("woff");
}
