.header__content {
  padding: 40px 0 40px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.header__sponsors {
  display: grid;
  gap: 30px;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.header__sponsors img {
  width: 100%;
  max-width: 160px;
}

.header__navigation {
  list-style: none;
  display: flex;
  gap: 26px;

  @media (max-width: 1320px) {
    display: none;
  }
}

.header__navigation a {
  color: var(--white);
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s;
}

.header__navigation a:hover {
  color: var(--dark-white);
}

.hero {
  background: no-repeat center url("../img/ph.jpg");
  background-size: cover;
  position: relative;
}

.hero__background {
  position: absolute;
  top: 0;
  right: 0;
  width: 795px;

  @media (max-width: 1320px) {
    display: none;
  }
}

.hero__content {


  @media (max-width: 1320px) {
    width: 100%;
  }
}

.hero__content img {
  @media (max-width: 1320px) {
    width: 100%;
  }
}

.hero__bottom {
  display: flex;
  align-items: center;

  @media (max-width: 1320px) {
    flex-direction: column;
  }
}

.hero__header {
  color: white;
  font-size: 47px;
  margin: 0;
  margin-top: 14px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 100px;

  @media (max-width: 1320px) {
    font-size: 32px;
  }
}

.hero__description {
  font-size: 28px;
  margin: 0;
  margin-bottom: 32px;
}

.hero__desriptionSecion {
  display: flex;
  justify-content: space-around;
  gap: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
  }

}