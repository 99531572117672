.footer {
  padding-bottom: 50px;
  background-size: cover;
  padding-top: 30px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  color: var(--yellow);

  @media (max-width: 1320px) {
    flex-direction: column;
  }
}

.footer-content__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-content__title {
  font-size: 34px;
  margin: 0;

  @media (max-width: 1320px) {
    margin-bottom: 14px;
  }
}

.footer-content__contacts {
  font-size: 28px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1320px) {
    margin-bottom: 24px;
  }
}

.footer-content__contacts a {
  text-decoration: none;
  color: var(--yellow);
  font-weight: normal;
  transition: color 0.3s;
}

.footer-content__contacts a:hover {
  color: var(--dark-white);
}

.footer-navigation {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: right;
  font-size: 32px;

  @media (max-width: 1320px) {
    text-align: left;
  }
}

.footer-navigation a {
  text-decoration: none;
  color: var(--yellow);
  font-weight: normal;
  transition: color 0.3s;
}

.footer-navigation a:hover {
  color: var(--dark-white);
}

.footer__divider {
  height: 2px;
  background: var(--yellow);
  margin: 52px 0;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--yellow);
  font-size: 20px;

  @media (max-width: 1320px) {
    flex-direction: column;
    align-items: center;
    font-size: 16px;
  }
}

.footer-bottom a {
  color: var(--yellow);
  text-decoration: none;
}

.footer-bottom__contacts {
  display: flex;
  gap: 26px;

  @media (max-width: 1320px) {
    margin-top: 16px;
  }
}

.footer__public-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}