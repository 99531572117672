.experts {
  background-position: left bottom;
  background-size: 100%;
}

.experts__content {
  position: relative;
  margin-bottom: 50px;
}

.about-header.experts__about-header {
  justify-content: center;
  margin-bottom: 50px;

  @media (max-width: 1320px) {
    margin-bottom: 25px;
  }
}

.experts__about-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.experts__about-content-image {
  display: block;
  flex-shrink: 0;
  width: 60px;
}

.secondary-title.experts__secondary-title {
  text-align: center;
  margin-bottom: 0;
}

.expert-slide {
  display: flex;
  background: #fff;
  color: #000;
  border-radius: 72px;
  overflow: hidden;
  height: 400px;

  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
}

.expert-slide__img {
  flex-shrink: 0;
}

.expert-slide__content {
  padding: 48px 30px;
}

.expert-slide__title {
  font-weight: bold;
  font-size: 26px;
  margin: 0;
  margin-bottom: 1em;
}

.expert-slide__text {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  margin-bottom: 1em;
}

.expert-slide__quota {
  font-size: 14px;
  margin: 0;
}